import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/przyklady-raportowania-sprzedazy.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    naplyw: file(
      relativePath: { eq: "naplyw-szans-crm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lejek: file(
      relativePath: { eq: "lejek-szans-crm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    konwersjalejek: file(
      relativePath: { eq: "crm-konwersja-lejka.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    aktywnosccrm: file(
      relativePath: { eq: "aktywnosc-handlowcow-crm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    analiza: file(
      relativePath: { eq: "analiza-sprzedazy-crm.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zamowienia: file(relativePath: { eq: "zamowienia-handlowe-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        oferty: file(relativePath: { eq: "oferty-handlowe-crm.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Pracazdalnacrmonline = ({ data }) => {
  return (
    <Artykul
      title="16 przykładów raportowania sprzedaży w firmie"
      articleImage={obrazekArtykulu}
      articleImageAlt="raporty sprzedaży przykłady"
      metaTitle="16 przykładów raportów sprzedażowych w firmie. Raportowanie sprzedaży z wykorzystaniem systemu CRM"
      metaDescription="Raportowanie sprzedaży w systemie CRM. Raporty sprzedażowe dla kierowników. Automatyczne i zawsze aktualne."
    >
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}>
        Koniec z poświęcaniem czasu managera na generowanie raportów sprzedaży – system CRM zrobi je za Ciebie!
      </h3>

      <p>
        Systemy CRM od wielu lat wspierają polskie firmy w organizowaniu skutecznego procesu sprzedaży 
        oraz koordynacji pracy, obecnie już nie tylko zespołów sprzedaży, ale całych firm. Managerowie 
        podejmują decyzje biznesowe na podstawie informacji, które posiadają. Z tego względu raportowanie 
        działań jest dla nich bardzo ważne i nie może być pracochłonne, czas to pieniądz.
      </p>

      <p style={{
          paddingBottom: 20,
        }}>
        Nie ma jednego słusznego zestawienia niezbędnych raportów sprzedaży, które sprawdzą się w każdej 
        firmie. Jako właściciele, managerowie na co dzień z pewnością mierzycie wyłącznie kluczowe dla Was 
        wskaźniki. Natomiast, gdy zajdzie taka potrzeba – sięgacie po więcej danych. Jest to zdrowe podejście.
      </p>
      <div className="text-block">
        <br />
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="OvD2Rdt2Klk"
          title="Raportowanie sprzedaży w firmie. System CRM."
        />
      </div>
      <p style={{
          paddingTop: 20,
        }}>
        System CRM gromadzi dane na temat potencjalnych transakcji, które obsługiwane są w&nbsp;ramach 
        zarządzania szansami sprzedaży. Ponadto opiera się na dokumentach sprzedażowych (zamówienia, oferty, faktury), 
        a&nbsp;nawet zdarzeniach pracowników. Poniżej prezentujemy konkretne przykłady raportowania sprzedaży w&nbsp;firmie.
      </p>

      <h3
        style={{
          textAlign: "left",
          paddingTop: 17,
          paddingBottom: 13,
        }}
      >
        Dlaczego warto skorzystać z&nbsp;raportowania wyników sprzedaży w&nbsp;systemie CRM?
      </h3>
      <p>
        Najważniejsze cechy raportowania sprzedaży w&nbsp;CRM to:
        <ol>
          <li><strong>automatyczność</strong> – zestaw raportów sprzedaży powstaje automatycznie na podstawie danych gromadzonych w&nbsp;systemie,</li>
          <li><strong>aktualność</strong> –  raporty okresowe tworzone są w czasie rzeczywistym i uwzględniają wszelkie najnowsze informacje,</li>
          <li><strong>mobilność</strong> – program CRM dostępny w&nbsp;wersji mobilnej pozwala dokonywać analiz w&nbsp;każdym miejscu i&nbsp;czasie,</li>
          <li><strong>przystępna forma</strong> – powstające w celu zaprezentowania sprzedaży zestawienia są&nbsp;atrakcyjne wizualnie.</li>
        </ol>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz raportować sprzedaż Twojej firmy?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>
              <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować CRM i&nbsp;korzystać z automatycznych raportów sprzedaży
            </Link>
          </div>
          </div>
    </section>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Raportowanie w procesie pozyskiwania nowych klientów/transakcji
      </h3>
      <p>
        Proces sprzedaży od momentu pierwszego kontaktu z klientem wspiera widok zarządzania szansami sprzedaży w CRM. 
        To uporządkowany plan działania dla każdego handlowca. Dobrze zaprojektowany i stale usprawniany zwiększa efektywność 
        pracowników. Na podstawie danych wprowadzanych przez handlowców do CRM tworzone są automatyczne raporty szczegółowe 
        sprzedaży, które pozwalają monitorować, ocenić i&nbsp;usprawnić cały proces.
      </p>
      <p>Zaliczyć można do nich:</p>
      <p>
        <strong>Napływ szans sprzedaży</strong>
      </p>
      <p>
        Raport sprzedaży pokazujący napływ szans w&nbsp;danym okresie oraz ich aktualny status (otwarta, wygrana, przegrana).
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.naplyw]}
        alts={["raportowanie sprzedaży przykład - napływ szans"]}
      />
      <p>
        <strong>
          Lejek szans sprzedaży w ujęciu ilościowym i&nbsp;wartościowym
        </strong>
      </p>
      <p>
        Raport pokazuje liczbę szans na każdym etapie procesu i ich potencjalną wartość.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.lejek]}
        alts={["wartościowy lejek szans sprzedaży"]}
      />

      <p>
        <strong>Przyczyny utraty</strong>
      </p>
      <p>Raport dostarcza dane w postaci uporządkowanej listy, pogrupowanych powodów utraty szans sprzedaży.</p>

      <p>
        <strong>Konwersja lejka</strong>
      </p>
      <p>
        Raport przedstawia procentową informację o tym ile szans przeszło do kolejnego etapu lub zostało utraconych. 
        To raport analizy sprzedaży pomocny w identyfikacji wąskich gardeł procesu.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.konwersjalejek]}
        alts={["Raport sprzedaży - konwersja lejka"]}
      />

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Tworzenie raportów na podstawie zdarzeń – Aktywność pracowników
      </h3>
      <p>
        Raport aktywności pracowników pozwala kierownikowi być na bieżąco z działaniami podwładnych. 
        Bez marnowania czasu na wielokrotne telefony i dopytywanie o szczegóły. Wszystkie informacje 
        na bieżąco pojawiają się na raporcie wraz z notatką pracownika. Wiesz, kto i co robił, 
        bez poświęcania dodatkowego czasu.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.aktywnosccrm]}
        alts={["Aktywność pracowników - raportowanie sprzedaży"]}
      />
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Analiza sprzedaży na podstawie dokumentów
      </h3>
      <p>
       Dokumenty zamówień, ofert i faktur pozwalają w&nbsp;systemie CRM na analizę każdego z tych 
       etapów z&nbsp;wykorzystaniem raportów szczegółowych sprzedaży. Dzięki nim będziesz w stanie 
       monitorować swoją sprzedaż z&nbsp;uwzględnieniem kontrahentów, handlowców, kategorii 
       produktów oraz wartości dokumentów.
      </p>
      <p>
        <strong>Ranking kontrahentów</strong>
      </p>
      <p>
        Ranking kontrahentów to raport wykonany na podstawie kwoty transakcji z dokumentów sprzedażowych. 
        Dzięki raportowi sprzedaży pokazującemu z jakim kontrahentem mieliśmy najniższą i najwyższą 
        wartość sprzedaży, z&nbsp;łatwością można ocenić jakość współpracy.
      </p>

      <p>
        <strong>Ranking sprzedawców</strong>
      </p>
      <p>
        Na tych samych dokumentach oparty jest ranking handlowców. 
        Raport pokazuje, który handlowiec wygenerował najwyższą wartość sprzedaży w&nbsp;danym okresie.
      </p>

      <p>
        <strong>Raport prezentujący sprzedaż w miesiącach</strong>
      </p>
      <p>
        Na podstawie dokumentów faktur możliwe jest raportowanie sprzedaży w danym 
        okresie (np.&nbsp;raport miesięczny lub inny raport okresowy), wskazując dynamikę swojej sprzedaży.
      </p>
      <p>
        <strong>Ranking kategorii produktowych</strong>
      </p>
      <p>
        Chcesz wiedzieć, która kategoria produktowa generuje Ci największy przychód? 
        Proszę bardzo, oto raport szczegółowy sprzedaży poszczególnych produktów.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.analiza]}
        alts={["raporty sprzedaży przykład - analiza sprzedaży"]}
      />
      <p>
        Co ważne –{" "}
        <strong>
           możesz zarządzać fakturami po stronie CRM lub <Link to="/integracja-crm-z-systemem-handlowym/">
              zintegrować nasz system z&nbsp;programem ERP
            </Link>, jeśli z takiego korzystacie.
        </strong>
      </p>
      <p>
        <strong>Oferty i zamówienia w CRM</strong>
      </p>
      <p>
       W przypadku ofertowania i zamówień również możemy zastosować to samo podejście i&nbsp;wyświetlić wartość ofert/zamówień:
        <ol>
          <li>wg pracownika handlowego,</li>
          <li>wg kontrahentów,</li>
          <li>wg kategorii produktowej, </li>
          <li>wg produktu,</li>
          <li>w miesiącach.</li>
        </ol>
      </p>

      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[
          data.zamowienia,
          data.oferty,
        ]}
        alts={[
          "zamówienia handlowe w crm, oferty handlowe w crm",
        ]}
      />

      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Indywidualne wykonywanie raportu
      </h3>
      <p>
        W systemie CRM możliwe jest także<strong> tworzenie raportów indywidualnych </strong>na podstawie danych 
        zbieranych w CRM lub możliwych do pobrania w&nbsp;procesie integracji z&nbsp;ERP.
      </p>
      <p>
        Przykłady indywidualnych raportów dotyczących sprzedaży:
        <ol>
          <li>produktywność zespołu (w tym velocity), </li>
          <li>rentowność współpracy z klientem. </li>
        </ol>
      </p>
      <h3
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Uprawnienia
      </h3>
      <p>
       System CRM umożliwia zarządzanie uprawnieniami, a więc to Ty możesz zdecydować o tym, 
       kto i jakie niezbędne raporty sprzedaży będzie mógł wyświetlać.
      </p>
      <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
       Tworzenie raportów sprzedażowych w CRM – podsumowanie
      </h2>
      <p>
        Raportowanie sprzedaży nie może być ani trudne ani pracochłonne. Różne rodzaje 
        raportów sprzedaży, aby miały sens, muszą przedstawiać aktualne dane dotyczące 
        sprzedaży. Czas managerów działu sprzedaży jest zbyt cenny, aby marnowali go na 
        proste i&nbsp;powtarzalne czynności, które z powodzeniem może wykonać za nich np. 
        system CRM. Przenieś wykonywanie raportów sprzedaży Twojego przedsiębiorstwa 
        do oprogramowania informatycznego i ciesz się automatyczną funkcją raportów sprzedaży.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz raportować sprzedaż Twojej firmy?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować CRM i&nbsp;korzystać z automatycznych raportów sprzedaży
            </Link>
          </div>
          </div>
    </section>

          <p style={{
          paddingTop: 30,
          textAlign: "center",
        }}>
       O innych możliwościach programu CRM piszemy także tutaj:
      </p>

      <p>
        <ul>
          <li>
            <Link to="/wsparcie-sprzedazy/">
              Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
            </Link>
          </li>
          <li>
            <Link to="/program-do-tworzenia-ofert-handlowych/">
              Wykorzystaj program do tworzenia ofert handlowych w celu poprawy swoich działań sprzedażowych
            </Link>
          </li>
          <li>
            <Link to="/wdrozenie-crm/">
              Porażki wdrożeń CRM - dlaczego się nie udaje?
            </Link>
          </li>
          <li>
            <Link to="/dashboard-managera/">
              Dashboard managera – jeden rzut oka, by poznać sytuację całej firmy
            </Link>
          </li>
        </ul>
      </p>

    </Artykul>
  );
};

export default Pracazdalnacrmonline;
